<script setup lang="ts">
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { locale, t } = useI18n()

const searchPath = localePath({
  name: 'search',
  query: {
    facetFilter: `searchable_tags.5.${locale.value}: ${t('global.hotdeal')}`,
  },
})
const hotDealsPath = computed(() => {
  switch (locale.value) {
    case 'en':
      return '/en/luxury-villas-rentals/book-now-for-summer'
    case 'fr':
      return '/fr/location-villas-luxe/reserver-pour-l-ete'
    default:
      return searchPath
  }
})

const onClickHotDeals = () =>
  trackEvent({
    event: 'homepage_favourites_click',
    suggestion: 'house',
  })
</script>

<template>
  <BaseContainer class="bg-gray-100 lg:max-w-full pt-8 pb-8">
    <BaseRow class="lg:justify-center lg:items-center">
      <BaseCol cols="24" md="12" sm="24">
        <BaseSectionContent :title="$t('home.hotdeals.title')">
          <template #header>
            <i18n-t
              keypath="home.hotdeals.text"
              tag="p"
              class="mb-8 max-w-screen-sm"
            >
              <template #bold>
                <strong>{{ $t('home.hotdeals.textBold') }}</strong>
              </template>
            </i18n-t>
            <BaseButton
              class="text-lg items-center"
              color="secondary"
              size="xl"
              :to="hotDealsPath"
              @click.prevent="onClickHotDeals"
            >
              {{ $t('home.hotdeals.link') }}
            </BaseButton>
          </template>
        </BaseSectionContent>
      </BaseCol>
      <BaseCol cols="24" md="12" sm="24" class="sm:pt-0 pt-8">
        <BaseNuxtImg
          class="lg:p-20"
          src="production/assets/images/homepage/summer-2025.webp"
          width="547"
          height="365"
          alt="Last minute Hot Deals"
        />
      </BaseCol>
    </BaseRow>
  </BaseContainer>
</template>
